<template>
  <section
    id="about_the_company"
    class="section-1 highlights image-right vclab-dashed-bg"
  >
    <div class="container">
      <div class="row">
        <div
          class="
            col-12 col-md-6
            pr-md-5
            align-self-center
            text-center text-md-left text
          "
        >
          <div data-aos="fade-up" class="row intro">
            <div class="col-12 p-0">
              <span class="pre-title">{{ $t("about_us") }}</span>
              <h2>
                <span class="featured"
                  ><span>{{ $t("about_company_1") }}</span></span
                >
                {{ $t("about_company_2") }}
              </h2>
              <p class="pr-10">
                {{ $t("company_intro") }}
              </p>
            </div>
          </div>
        </div>
        <div
          class="
            col-12 col-md-6
            pr-md-5
            align-self-center
            text-center text-md-left text
          "
        >
          <div class="row items">
            <div data-aos="fade-up pr-10" class="col-12 col-md-6 p-0 item">
              <h4>
                <i class="fa-solid fa-lock mr-10"></i>{{ $t("security") }}
              </h4>
              <p class="pr-20">
                {{ $t("security_desc") }}
              </p>
            </div>
            <div data-aos="fade-up" class="col-12 col-md-6 p-0 pr-md-4 item">
              <h4><i class="fa-solid fa-ribbon mr-10"></i>{{ $t("trust") }}</h4>
              <p>
                {{ $t("trust_desc") }}
              </p>
            </div>
          </div>
          <div class="row items">
            <div data-aos="fade-up" class="col-12 col-md-6 p-0 item">
              <h4>
                <i class="fa-solid fa-user-group mr-10"></i
                >{{ $t("innovation") }}
              </h4>
              <p class="pr-20">
                {{ $t("innovation_desc") }}
              </p>
            </div>
            <div data-aos="fade-up" class="col-12 col-md-6 p-0 pr-md-4 item">
              <h4>
                <i class="fa-solid fa-book mr-10"></i>{{ $t("expertise") }}
              </h4>
              <p>
                {{ $t("expertise_desc") }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {

}
</script>

<style>
.vclab-dashed-bg {
  background: repeating-linear-gradient(
    -45deg,
    #fff,
    #f4f4f4 10px,
    #ffffff 1px,
    #fefafa 35px
  );
}
</style>