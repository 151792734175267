import i18n from '@/i18n'
import { createRouter, createWebHistory } from 'vue-router'

import Home from '@/views/Home/Home'
import Team from '@/views/Team/Team'
import Services from '@/views/Services/Services'
import Articles from '@/views/Articles/Articles'
import ArticleTpl from '@/views/Articles/ArticleTpl'
import ServiceTpl from '@/views/Services/ServiceTpl'
import Contacts from '@/views/Contacts'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      title: 'home',
      metaTags: [
        {
          name: 'description',
          content: 'LB Venture, Tax & Law advisory in Berlin and Germany'
        }, {
          name: 'keywords',
          content: 'legal consulting, venture consulting in Berlin, consulting in Germany'
        }
      ]
    }
  }, {
    path: '/articles',
    name: 'articles',
    component: Articles,
    meta: {
      title: 'articles',
    }
  },{
    path: '/articles/:article',
    name: 'single_article',
    props: true,
    component: ArticleTpl,
  }, {
    path: '/team',
    name: 'team',
    component: Team,
    meta: {
      title: 'our_team',
    }
  }, {
    path: '/services',
    name: 'services',
    component: Services,
    meta: {
      title: 'our_services',
    }
  }, {
    path: '/services/interim-cfo',
    name: 'interim_cfo',
    component: ServiceTpl,
    meta: {
      title: 'services',
    }
  }, {
    path: '/services/fin-advisory',
    name: 'fin_advisory',
    component: ServiceTpl,
    meta: {
      title: 'services',
    }
  }, {
    path: '/services/startup-consulting',
    name: 'startup_consulting',
    component: ServiceTpl,
    meta: {
      title: 'services',
    }
  }, {
    path: '/services/m-and-a',
    name: 'm_and_a',
    component: ServiceTpl,
    meta: {
      title: 'services',
    }
  }, {
    path: '/services/ifrs',
    name: 'ifrs',
    component: ServiceTpl,
    meta: {
      title: 'services',
    }
  }, {
    path: '/services/tax-and-legal',
    name: 'tax_and_legal',
    component: ServiceTpl,
    meta: {
      title: 'services',
    }
  },

  {
    path: '/contacts',
    name: 'contacts',
    component: Contacts,
    meta: {
      title: 'contacts',
    }
  },
]

const router = createRouter({
  mode: 'history',
  history: createWebHistory(),// Provide the history implementation to use. We are using the hash history for simplicity here.
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  },
})

// display metatags and page titles on page reloads. see here for details: https://www.digitalocean.com/community/tutorials/vuejs-vue-router-modify-head
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.

  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) document.title = i18n.global.t(nearestWithTitle.meta.title) + ' | VC Lab';

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create, so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
    // Add the meta tags to the document head.
    .forEach(tag => { document.head.appendChild(tag) });

  next()
})

export default router