export const lba_team = [
  {
    name: "l_bush",
    position: "managing_partner",
    fb: "www.facebook.com",
    email: "info@ventureslab.de",
    ava: "liubov_bush.jpg",
    linkedin: "https://linkedin.com/in/liubov-bush-mba-cpa-a90032172"
  },
  {
    name: "a_rogov",
    position: "partner_finance",
    fb: "www.facebook.com",
    email: "info@ventureslab.de",
    ava: "alexey_rogov.jpg",
    linkedin: "https://linkedin.com/in/alexey-l-rogov"
  },
  {
    name: "p_medvedev",
    position: "partner_startups",
    fb: "www.facebook.com",
    email: "info@ventureslab.de",
    ava: "pavel_me.jpg",
    linkedin: "https://linkedin.com/in/pamedvedev"
  },
]


export const articles_list = [
  {
    lang: 'en',
    details: [
      { name: "Rental of virtual land in an online game", pic: 'news-1.jpg', article_id: "rental_virtual_land", author: "VC Lab", desc: `German Federal Tax Court held (18 November 2021, V R 38/19) that in-game 'leasing' of virtual land in an online game is not subject to VAT, but the exchange of a game currency into fiat money gives rise to actual supply subject to VAT...` },
      { name: "Act to temporarily lower VAT rate on gas deliveries via the natural gas network", pic: 'news-2.jpg', article_id: "vat_on_gas", author: "VC Lab", desc: `In order to cushion the burden on citizens from the increased gas prices, the draft law provides for VAT rate on the supply of gas via the natural gas network to be reduced to 7 percent from...` },
      { name: "Federal government launches second relief package", pic: 'news-3.jpg', article_id: "second_relief_package", author: "VC Lab", desc: `The consequences of the corona pandemic and the Russian war of aggression against Ukraine have had a significant impact on the citizens of Germany. The coalition factions have therefore agreed on a comprehensive package of...` },
      { name: "Tax relief supports citizens", pic: 'news-4.jpg', article_id: "tax_relief", author: "VC Lab", desc: `On March 16, 2022, the federal cabinet passed the draft of a tax relief law for 2022. The federal government wants to support citizens with targeted tax relief, in particular to take into account the significantly...` },
    ]
  }, {
    lang: 'de',
    details: [
      { name: "Rental of virtual land in an online game", pic: 'news-1.jpg', article_id: "rental_virtual_land", author: "VC Lab", desc: `German Federal Tax Court held (18 November 2021, V R 38/19) that in-game 'leasing' of virtual land in an online game is not subject to VAT, but the exchange of a game currency into fiat money gives rise to actual supply subject to VAT...` },
      { name: "Act to temporarily lower VAT rate on gas deliveries via the natural gas network", pic: 'news-2.jpg', article_id: "vat_on_gas", author: "VC Lab", desc: `In order to cushion the burden on citizens from the increased gas prices, the draft law provides for VAT rate on the supply of gas via the natural gas network to be reduced to 7 percent from...` },
      { name: "Federal government launches second relief package", pic: 'news-3.jpg', article_id: "second_relief_package", author: "VC Lab", desc: `The consequences of the corona pandemic and the Russian war of aggression against Ukraine have had a significant impact on the citizens of Germany. The coalition factions have therefore agreed on a comprehensive package of...` },
      { name: "Tax relief supports citizens", pic: 'news-4.jpg', article_id: "tax_relief", author: "VC Lab", desc: `On March 16, 2022, the federal cabinet passed the draft of a tax relief law for 2022. The federal government wants to support citizens with targeted tax relief, in particular to take into account the significantly...` },
    ]
  }
]