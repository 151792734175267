<template>
  <section id="subscribe" class="lb-subscribe-bg odd subscribe">
    <div class="container smaller">
      <div class="row">
        <div class="col-12 col-md-6 m-md-0 intro">
          <span class="pre-title m-0">{{ $t("timely_updates") }}</span>
          <h2 v-html="$t('know_first')" />
          <p class="text-dark">
            {{ $t("know_first_desc") }}
          </p>
        </div>
        <div class="col-12 col-md-6">
          <form
            action="php/form.php"
            id="nexgen-subscribe"
            class="row m-auto items"
          >
            <input type="hidden" name="section" value="nexgen_subscribe" />

            <input type="hidden" name="reCAPTCHA" />
            <!-- Remove this field if you want to disable recaptcha -->

            <div class="col-12 mt-0 input-group align-self-center item">
              <input
                type="text"
                name="name"
                class="form-control less-opacity field-name"
                :placeholder="$t('name')"
                v-model="name"
              />
            </div>
            <div class="col-12 input-group align-self-center item">
              <input
                type="email"
                name="email"
                class="form-control less-opacity field-email"
                :placeholder="$t('email')"
                v-model="email"
              />
            </div>
            <div class="col-12 input-group align-self-center item">
              <a
                data-aos="zoom-in"
                class="btn primary-button"
                @click="sendSubscribeEmail"
                >{{ $t("subscribe") }}</a
              >
            </div>
            <div class="col-12 item">
              <span class="form-alert mt-3 mb-0"></span>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { ref, toRefs, reactive } from 'vue'
import i18n from '@/i18n'

export default {
  name: "LbSubscribe",
  setup() {
    const isAnyFieldEmpty = ref(false)
    const sendObj = reactive({
      name: null,
      email: null,
    })

    function sendSubscribeEmail() {
      isAnyFieldEmpty.value = Object.values(sendObj).some(val => !val)
      if (isAnyFieldEmpty.value) {
        console.log('either of the fields is empty')
        return // <---- breakpoint
      }

      let email_body = ''
      Object.keys(sendObj).forEach(key => {
        email_body += key + ': ' + sendObj[key] + "%0D%0A"
      })

      location.href = `mailto:${process.env.VUE_APP_EMAIL}?subject=Subscribe%20to%20VC%20Lab%20periodical%20Emails&body=Hi%20${sendObj.name}!%20Please%20send%20this%20email%20to%20voluntarily%20subscribe%20${sendObj.email}%20mailbox%20to%20our%20periodical%20emails.`
    }

    return {
      isAnyFieldEmpty,
      ...toRefs(sendObj),

      sendSubscribeEmail,
    }
  },
};
</script>