export const LbQuotesObj = {
    interim_cfo: {
      text: "cite_1",
      author: "cite_1_author"
    },
    fin_advisory: {
      text: "cite_2",
      author: "cite_2_author"
    },
    startup_consulting: {
      text: "cite_3",
      author: "cite_3_author"
    },
    m_and_a: {
      text: "cite_4",
      author: "cite_4_author"
    },
    ifrs: {
      text: "cite_5",
      author: "cite_5_author"
    },
    tax_and_legal: {
      text: "cite_6",
      author: "cite_6_author"
    }
  };