<template>
  <main role="main">
    <nav-bar class="desktop" />
    <div class="mobile">
      <mobile-menu />
    </div>

    <router-view :key="$route.path" />

    <main-footer />
    <cookie-popup />
  </main>
</template>

<script>
import { useStore } from 'vuex'
import MainFooter from '@/components/MainFooter.vue'
import NavBar from '@/components/NavBar.vue'
import { onMounted, computed } from 'vue'
import CookiePopup from '@/components/CookiePopup.vue'
import MobileMenu from './components/MobileMenu.vue'

export default {
  name: "App",
  components: { MainFooter, NavBar, CookiePopup, MobileMenu },
  setup() {
    const store = useStore()
    const browserlanguage = computed(() => store.getters.browserlanguage)

    onMounted(async () => {
      await store.dispatch("userLang") // <-------- set Interface language
    })

    return {
      browserlanguage
    }
  },
  watch: {
    browserlanguage() {
      // we use watch cuz at very first browserlanguage is empty;
      this.$i18n.locale = this.browserlanguage;
    },
  },
};
</script>