<template>
  <footer>
    <!-- Footer [content] -->
    <section id="footer" class="odd footer offers pb-20">
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-5 footer-left">
            <svg-logo isSmall />
            <p>
              {{ $t("slogan_2") }}
            </p>
            <ul class="navbar-nav">
              <li class="nav-item">
                <a href="#" class="nav-link">
                  <i class="fas fa-phone-alt mr-10"></i>+49 30 2359 19007
                </a>
              </li>
              <li class="nav-item">
                <a href="mailto:info@ventureslab.de" class="nav-link">
                  <i class="fas fa-envelope mr-10"></i>
                  <span class="__cf_email__">{{ $t("email_protected") }}</span>
                </a>
              </li>

              <li class="nav-item social">
                <a href="#" class="nav-link"
                  ><i class="fab fa-linkedin-in mr-10"></i>
                  <span class="__cf_email__">{{ $t("Linkedin") }}</span>
                </a>
              </li>

              <li class="nav-item">
                <a href="#" class="nav-link">
                  <i class="fas fa-map-marker-alt mr-10"></i>
                  {{ $t("address") }}
                </a>
              </li>
              <li class="nav-item bFlex mt-20">
                <lang-selector />
              </li>
            </ul>
          </div>
          <div class="col-12 col-lg-7 p-0 footer-right">
            <div class="row items">
              <div class="col-12 col-lg-7 item">
                <div class="card">
                  <h4>{{ $t("our_services") }}</h4>
                  <router-link
                    v-for="(service, i) in serviceList"
                    :key="i"
                    :to="service.link"
                  >
                    <i
                      :class="`${service.icon}`"
                      class="mr-10"
                      style="color: rgba(255, 255, 255, 0.9)"
                    ></i
                    >{{ $t(service.name) }}
                  </router-link>
                </div>
              </div>
              <div class="col-12 col-lg-5 item">
                <div class="card">
                  <h4>{{ $t("about_us") }}</h4>
                  <router-link to="/"
                    ><i class="fa-solid fa-angle-right mr-10"></i
                    >{{ $t("about_company_1") }}
                    {{ $t("about_company_2") }}</router-link
                  >
                  <router-link to="/team"
                    ><i class="fa-solid fa-angle-right mr-10"></i
                    >{{ $t("team") }}</router-link
                  >

                  <h4 class="mt-10">{{ $t("materials") }}</h4>
                  <router-link to="/articles"
                    ><i class="fa-solid fa-angle-right mr-10"></i
                    >{{ $t("articles") }}</router-link
                  >
                  <!-- <a href="#"
                    ><i class="fa-solid fa-angle-right mr-10"></i
                    >{{ $t("cookies_and_privacy") }}</a
                  > -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Copyright -->
      <div class="container p-3 odd copyright">
        <div class="row">
          <div class="col-12 col-md-6 p-3 text-center text-lg-right">
            <p>
              <span>© 2022 </span>
              <span v-html="$t('co_name') + ' '" />
              <span v-html="$t('rights_reserved')" />
            </p>
          </div>
        </div>
      </div>
    </section>
  </footer>
</template>

<script>
import SvgLogo from "@/components/SvgLogo.vue";
import { LbServiceList } from "@/config/defaults";
import LangSelector from './LangSelector.vue';

export default {
  name: "MainFooter",
  components: { SvgLogo, LangSelector },
  data() {
    return {
      serviceList: LbServiceList,
    };
  },
  methods: {
    //..
  },
};
</script>