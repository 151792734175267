<template>
  <div
    v-if="!cookiesAlreadyAccepted"
    class="b-fadein-b"
    style="
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 3;
      width: 100%;
    "
  >
    <div
      class="p-3 vc-lab-cookie-popup shadow-xl bB-all bB-easy"
      role="alert"
      style="float: right"
    >
      <p class="small fs-14">
        {{ $t("cookies") }}
      </p>

      <div class="">
        <button
          type="button"
          class="btn btn-dark btn-xs btn-transition"
          @click="saveUserChoice"
        >
          {{$t("understand")}}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { getFromLS } from '@/utils/storage'

export default {
  setup() {
    const store = useStore()
    let cookiesAlreadyAccepted = ref(null)

    onMounted(async () => {
      // check if cookies accepted before
      cookiesAlreadyAccepted.value = await getFromLS("acceptCookies")
    })

    async function saveUserChoice() {
      await store.dispatch("saveCookieAccept", true)
      cookiesAlreadyAccepted.value = true
    }

    return {
      cookiesAlreadyAccepted,

      saveUserChoice
    }
  }
}
</script>
<style scoped>
.vc-lab-cookie-popup {
  width: 50%;
  border-radius: 6px;
  margin-right: 10px;
  margin-bottom: 10px;
  padding:20px;
  background-color: var(--section-2-bg-color);
}
@media (max-width: 768px) {
  .vc-lab-cookie-popup {
    width: 100%;
    border-radius: 0;
    padding:15px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    margin-right: 0;
    margin-bottom: -1px;
  }
}
</style>