export default {
  "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En"])},
  "de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De"])},
  "ru": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ру"])},
  "zh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中文"])},
  "en_full": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
  "de_full": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deutsch"])},
  "ru_full": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Русский"])},
  "zh_full": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中文"])},
  "co_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Venture Consulting LAB"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berlin, Germany"])},
  "VENTURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VENTURE"])},
  "CONSULTING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONSULTING LAB"])},
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "slogan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berlin company, International experience"])},
  "slogan_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Best international consulting, right next to you in Berlin"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
  "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
  "get_in_touch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get in touch"])},
  "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Know more"])},
  "about_company_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The"])},
  "about_company_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company"])},
  "about_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About us"])},
  "company_intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At Venture Consulting LAB we shall design your financial function to meet your strategic objectives & to optimize operational needs."])},
  "security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Security"])},
  "security_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Industry vanguard safeguards will keep your data safe"])},
  "trust": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trust"])},
  "trust_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As your trusted advisers we will provide consultation on cyber & strategy risks"])},
  "innovation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Innovation"])},
  "innovation_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We leverage technology & turn data into insight to strategize"])},
  "expertise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expertise"])},
  "expertise_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our tech acumen & advisory analytics will add value to your success"])},
  "key_services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Key services"])},
  "key_services_header_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excellence in"])},
  "key_services_header_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services"])},
  "key_services_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are leaders in providing consultancy services supported by our cutting-edge experience in finance, tax, law, and other adjacent spheres, both internationally and in Germany."])},
  "see_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See all"])},
  "how_it_works": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How it works in practice"])},
  "how_it_works_header_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Process"])},
  "how_it_works_header_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Management"])},
  "how_it_works_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We work with innovative methodologies to ensure that the entire reformatting process is done from start to finish as planned."])},
  "Collection of information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collection of information"])},
  "steps_desc_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "Strategic planning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strategic planning"])},
  "steps_desc_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "Assignment of responsibilities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assignment of responsibilities"])},
  "steps_desc_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "Formatting process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formatting process"])},
  "steps_desc_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "Continuity formalization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuity formalization"])},
  "steps_desc_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "our_services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our services"])},
  "select_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select related service"])},
  "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])},
  "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services"])},
  "team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Team"])},
  "our_team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Team"])},
  "articles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Articles"])},
  "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacts"])},
  "managing_partner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Managing Partner"])},
  "partner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partner"])},
  "partner_finance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partner, Financial Services"])},
  "partner_startups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partner, Startups & Tech"])},
  "rights_reserved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GMBH &middot; All rights reserved"])},
  "email_protected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "cookies_and_privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cookies & Privacy policies"])},
  "cite_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I hire people smarter than me and get out of the way"])},
  "cite_1_author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steve Jobs"])},
  "cite_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The best investment is in the tools of one's own trade"])},
  "cite_2_author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benjamin Franklin"])},
  "cite_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whether you think you can or whether you think you can't, you're right!"])},
  "cite_3_author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Henry Ford"])},
  "cite_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It takes 20 years to build the reputation and 5 minutes to ruin it. If you think about that you'll do things differently."])},
  "cite_4_author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warren Buffet"])},
  "cite_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Always deliver more than expected"])},
  "cite_5_author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Larry Page"])},
  "cite_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ignorance of tax laws does not exempt you from the obligation to pay taxes.  But the knowledge often"])},
  "cite_6_author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meyer A. Rothschild"])},
  "related_quote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Related quote"])},
  "team_slogan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We like what we do"])},
  "pro_team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span class='featured'><span>Professional</span></span> Team"])},
  "team_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ethics and integrity are the bases on which our professionals build their careers. They are fundamentals that become daily attitudes."])},
  "why_we": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Why we"])},
  "philosophy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We know how money works.  If it works for us, it will work for you."])},
  "timely_updates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get timely updates"])},
  "know_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span class='featured'><span>Know</span></span> First"])},
  "know_first_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Follow closely and receive content about our company and news about the current market."])},
  "subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscribe"])},
  "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tags"])},
  "interim_cfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interim CFO"])},
  "interim_cfo_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unbiased perspective, blue chip finance tool' kit and leadership expertise"])},
  "fin_advisory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financial Advisory"])},
  "fin_advisory_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project based financial expertise and finance function in a corporate setting"])},
  "startup_consulting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["StartUp Consulting"])},
  "startup_consulting_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hands on advice to get your real money-making venture over to the goal line"])},
  "m_and_a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["M&A & Company Restructuring"])},
  "m_and_a_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["M&A as a strategic footing for new businesses and opportunities"])},
  "ifrs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IFRS Implementation"])},
  "ifrs_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To bring transparency & enhance comparability of financial information for your investors"])},
  "tax_and_legal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["International Tax & Legal"])},
  "tax_and_legal_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complex international projects structuring"])},
  "materials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Materials"])},
  "featured_articles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["featured articles"])},
  "related_articles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Related Popular Materials"])},
  "l_bush": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liubov Bush"])},
  "a_rogov": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alexey Rogov"])},
  "p_medvedev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pavel Medvedev"])},
  "share_article": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share this article"])},
  "other_services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other Services"])},
  "l_bush_exp_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liubov is an accomplished Tax and Finance industry executive with 25 years of commercial and general management experience at both public and private companies. Having spent 15 years as the CEO at Venture Consulting LLC, Liubov brings a wealth of experience to numerous business areas and integrated knowledge of a variety of tax areas, including international and EU taxation."])},
  "l_bush_exp_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liubov served as executive director at Lukarco Finance B.V. (the Netherlands) and CFO of a regional subsidiary of Fischer Sports GmbH (Austria)"])},
  "l_bush_exp_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MBA in Banking and Finance from Dowling College of New York"])},
  "l_bush_exp_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CPA qualified accountant"])},
  "a_rogov_exp_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alexey has over 20 years of experience as CFO and board director, managing financial assets in the amount of $15 billion. During his career, he has participated in preparing and conducting several public offerings and raising finance deals, providing strategic leadership and creating shareholder value. "])},
  "a_rogov_exp_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prior to joining Venture Consulting LAB Alexey was Head of the Financial Services Department at PJSC Lukoil where he oversaw the group's and JVs' financial facilities in more than 30 countries. Alexey restructured group foreign assets, designed M&A deals and managed intragroup capital/dividend policy."])},
  "a_rogov_exp_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MBA in International Banking and Finance"])},
  "a_rogov_exp_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MS in Electronics by MIPT"])},
  "p_medvedev_exp_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pavel has a versatile understanding of tech startups and product/business processes."])},
  "p_medvedev_exp_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pavel cofounded and CEOed the SaaS startup Bagit which served customers from 15 countries and achieved a 30% MoM growth in 11 straight months with only seed investment. In his project he implemented many fintech solutions and integrated the world's biggest ePayment gateways (AliPay, WeChat Pay, PayPal). Bagit demonstrated stable growth in highly complicated, fast-pace markets, including China, Europe and CIS/Russia"])},
  "p_medvedev_exp_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currently Pavel is also Head of Tech Product at Alibaba group. Previously, he had managed the BD desk at EY Beijing"])},
  "p_medvedev_exp_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pavel studied Chinese language and economy at the Yanshan University and at the Institute of Asian and African Countries"])},
  "cookies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["According to cookie policy best practice, we inform all our users about cookie usage by our website. Our website does not use cookies."])},
  "understand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Got it"])},
  "subscription_to_emails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscription"])},
  "uu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["uu"])}
}