<template>
  <page-hero-tpl />

  <section class="section-1 single">
    <div class="container">
      <div class="row content">
        <!---------------------------------------------------------- MAIN -->
        <div class="col-12 col-lg-8 text vclab-service-page">
          <div class="row" v-html="fileContent"></div>
        </div>

        <!---------------------------------------------------------- SIDEBAR -->
        <side-bar />
      </div>
    </div>
  </section>

  <related-articles />

  <lb-subscribe />
</template>

<script>
import SideBar from '@/views/Services/components/SideBar.vue'
import LbSubscribe from '@/components/LbSubscribe.vue'
import RelatedArticles from '@/components/RelatedArticles.vue'
import PageHeroTpl from '@/components/PageHeroTpl.vue'
import { useRoute } from 'vue-router'

import { marked } from 'marked'

import interim_cfo_en from '@/md/services/en/interim_cfo.md'
import interim_cfo_ru from '@/md/services/ru/interim_cfo.md'
import interim_cfo_de from '@/md/services/de/interim_cfo.md'
import fin_advisory_en from '@/md/services/en/fin_advisory.md'
import fin_advisory_ru from '@/md/services/ru/fin_advisory.md'
import fin_advisory_de from '@/md/services/de/fin_advisory.md'
import startup_consulting_en from '@/md/services/en/startup_consulting.md'
import startup_consulting_ru from '@/md/services/ru/startup_consulting.md'
import startup_consulting_de from '@/md/services/de/startup_consulting.md'
import m_and_a_en from '@/md/services/en/m_and_a.md'
import m_and_a_ru from '@/md/services/ru/m_and_a.md'
import m_and_a_de from '@/md/services/de/m_and_a.md'
import ifrs_en from '@/md/services/en/ifrs.md'
import ifrs_ru from '@/md/services/ru/ifrs.md'
import ifrs_de from '@/md/services/de/ifrs.md'
import tax_and_legal_en from '@/md/services/en/tax_and_legal.md'
import tax_and_legal_ru from '@/md/services/ru/tax_and_legal.md'
import tax_and_legal_de from '@/md/services/de/tax_and_legal.md'

export default {
  name: "ServiceTpl",
  components: {
    SideBar,
    LbSubscribe,
    RelatedArticles,
    PageHeroTpl,
  },
  data() {
    return {
      obj: {
        interim_cfo_en: interim_cfo_en,
        interim_cfo_ru: interim_cfo_ru,
        interim_cfo_de: interim_cfo_de,
        fin_advisory_en: fin_advisory_en,
        fin_advisory_ru: fin_advisory_ru,
        fin_advisory_de: fin_advisory_de,
        startup_consulting_en: startup_consulting_en,
        startup_consulting_ru: startup_consulting_ru,
        startup_consulting_de: startup_consulting_de,
        m_and_a_en: m_and_a_en,
        m_and_a_ru: m_and_a_ru,
        m_and_a_de: m_and_a_de,
        ifrs_en: ifrs_en,
        ifrs_ru: ifrs_ru,
        ifrs_de: ifrs_de,
        tax_and_legal_en: tax_and_legal_en,
        tax_and_legal_ru: tax_and_legal_ru,
        tax_and_legal_de: tax_and_legal_de,
      },
    };
  },
  computed: {
    routeName() {
      const route = useRoute()
      return route.name
    },
    fileContent() {
      const tempCn = this.browserlanguage === 'zh' ? 'en' : this.browserlanguage
      return marked(`${this.obj[this.routeName + '_' + tempCn]}`)
    },
    browserlanguage() {
      return this.$store.getters.browserlanguage
    },
  },
};
</script>

<style>
:root {
  --hero-bg-color: #080d10;
  --section-1-bg-color: #ffffff;
  --section-2-bg-color: #eef4ed;
  --section-3-bg-color: #111117;
}
</style>