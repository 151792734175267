import { createApp } from 'vue'
import App from './App.vue'
import router from '@/assets/js/router.js'
import store from '@/store/index.js'

// CSS
// vendor
import './assets/styles/bootstrap.css'
import './assets/styles/slider.css'
// theme
import './assets/styles/main.css'
import './assets/styles/default.css'
// lb
import './assets/styles/b_main.scss'
import './assets/styles/b_helpers.scss'
import '@/assets/styles/b_modals.scss'
import './assets/styles/b_colors.scss'


// Font Awesome icons
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from '@fortawesome/free-solid-svg-icons'
library.add(fas);
import { fab } from '@fortawesome/free-brands-svg-icons';
library.add(fab);
import { far } from '@fortawesome/free-regular-svg-icons';
library.add(far);
import { dom } from "@fortawesome/fontawesome-svg-core";
import i18n from '@/i18n'
dom.watch();


// CREATE APP
const app = createApp(App)

// add custom components
app.component("font-awesome-icon", FontAwesomeIcon);

// ADD ROUTER AND MOUNT APP
app.use(i18n)
app.use(router)
app.use(store)
app.mount('#app')