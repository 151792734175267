<template>
  <button
    class="btn smooth-anchor mr-15"
    :class="deutschLang"
    style="padding: 3px 20px"
    @click="changeLang('de')"
  >
    {{ $t("de") }}
  </button>
  <button
    class="btn smooth-anchor mr-15"
    :class="englishLang"
    style="padding: 3px 20px"
    @click="changeLang('en')"
  >
    {{ $t("en") }}
  </button>
    <button
    class="btn smooth-anchor mr-15"
    :class="chineseLang"
    style="padding: 3px 20px"
    @click="changeLang('zh')"
  >
    {{ $t("zh") }}
  </button>
  <button
    class="btn smooth-anchor"
    :class="russianLang"
    style="padding: 3px 20px"
    @click="changeLang('ru')"
  >
    {{ $t("ru") }}
  </button>
</template>
<script>
export default {
  computed: {
    browserlanguage() {
      return this.$store.getters.browserlanguage;
    },
    englishLang() {
      return this.browserlanguage === "en"
        ? "primary-button"
        : "outline-button";
    },
    chineseLang() {
      return this.browserlanguage === "zh"
        ? "primary-button"
        : "outline-button";
    },
    deutschLang() {
      return this.browserlanguage === "de"
        ? "primary-button"
        : "outline-button";
    },
    russianLang() {
      return this.browserlanguage === "ru"
        ? "primary-button"
        : "outline-button";
    },
  },
  methods: {
    changeLang(lang) {
      this.$store.dispatch("setLsLang", lang);
      this.$i18n.locale = lang;
    },
  },
};
</script>