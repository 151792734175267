<template>
  <router-link class="navbar-brand light" to="/">
    <span class="brand" :class="{ 'fs-16': isSmall }">
      <span class="featured mb-10">
        <span class="first mr-5">{{
          $t("VENTURE")
        }}</span>
      </span>
      <br v-if="isSmall" />
      <span class="last ml-15" :class="{ 'ammended-color': menuColorDark }">{{
        $t("CONSULTING")
      }}</span>
    </span>
  </router-link>
</template>

<script>
export default {
  name: "SvgLogo",
  props: {
    isSmall: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    menuColorDark() {
      return this.$route.name === 'home'
    }
  }
};
</script>

<style scoped>
.ammended-color {
  color: #21333e !important;
}
@media (max-width: 768px) {
  .navbar-brand.light {
    font-size: 16px;
  }
  .navbar-brand.light .brand {
    letter-spacing: 2px;
  }
}
</style>