<template>
  <section id="slider" class="hero p-0 odd featured">
    <page-hero-tpl />
  </section>

  <div class="container mt-50">
    <template
      v-for="(item, i) in articlesListOnUserLang.details"
      :key="`list_of_articles_${i}`"
    >
      <div class="row bB-all bB-eeasy mb-20 b-rad-8 p-10">
        <div class="col-12 item mb-5">
          <router-link :to="`/articles/${item.article_id}`"
            ><h4 class="text-underline">{{ item.name }}</h4></router-link
          >

          <p style="text-align: justify">
            {{ item.desc }}
          </p>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import PageHeroTpl from '@/components/PageHeroTpl.vue'
import { articles_list } from "@/utils/raw_data"

export default {
  name: "ArticlesList",
  components: { PageHeroTpl },
  setup() {
    const store = useStore()
    // now we only have articles in En and De, for all other langs, we display En
    const lang = computed(() => {
      const t = store.getters.browserlanguage
      return t === 'en' || t === 'de' ? t : 'en'
    })
    const articlesListOnUserLang = articles_list.find(obj => obj.lang === lang.value)

    onMounted(() => {
      //..
    })

    return {
      articlesListOnUserLang,
    }
  }
};
</script>