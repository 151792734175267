<template>
  <section class="hero p-0 odd featured">
    <page-hero-tpl :specificName="humanName" />
  </section>

  <section id="our_services" class="section-2 offers">
    <div class="container">
      <div class="row intro">
        <div class="col-12 col-md-9 align-self-center text-center text-md-left">
          <span class="pre-title" style="display: unset">{{
            $t("key_services")
          }}</span>
          <h2>
            {{ $t("key_services_header_1") }}
            <span class="featured"
              ><span>{{ $t("key_services_header_2") }}</span></span
            >
          </h2>
          <p>
            {{ $t("key_services_desc") }}
          </p>
        </div>
      </div>
      <div class="row justify-content-center items">
        <template v-for="(service, i) in serviceList" :key="i">
          <router-link :to="service.link" class="col-12 col-md-6 col-lg-4 item">
            <div class="card">
              <p><i :class="`${service.icon} fa-3x`"></i></p>
              <h4 class="responsive-h4">{{ $t(service.name) }}</h4>
              <p>{{ $t(service.desc) }}</p>
            </div>
          </router-link>
        </template>
      </div>
    </div>
  </section>
</template>

<script>
import { LbServiceList } from '@/config/defaults'
import PageHeroTpl from '@/components/PageHeroTpl.vue'

export default {
  name: "OurServices",
  components: { PageHeroTpl },
  setup() {
    const serviceList = LbServiceList


    return {
      serviceList
    }
  }
}
</script>