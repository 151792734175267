<template>
  <section id="slider" class="hero p-0 odd featured">
    <div class="swiper-container no-slider animation slider-h-60 slider-h-auto">
      <div class="swiper-wrapper">
        <div class="swiper-slide slide-center">
          <img
            src="@/assets/images/bg-wide.jpg"
            alt="Full Image"
            class="full-image"
            data-mask="80"
          />

          <div class="slide-content row text-center">
            <div class="col-12 mx-auto inner">
              <nav
                aria-label="breadcrumb"
                class="desktop"
              >
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link to="/"> {{ $t("home") }}</router-link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    {{ $t(subpage) }}
                  </li>
                </ol>
              </nav>

              <h1 class="mb-0 title effect-static-text" style="text-transform:capitalize">{{ $t(pageName) }}</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { useRoute } from 'vue-router'
import { onMounted } from 'vue'

export default {
  name: "PageHeroTpl",
  props:{
    specificName:{
      required:false,
      type:String,
    }
  },
  setup(props) {
    const route = useRoute()
    const pageName = props.specificName ? props.specificName : route.name
    const subpage = route.path.split("/")[1]
    

    onMounted(()=>{
      //..
    })

    return {
      pageName,
      subpage
    }
  }
};
</script>

<style scoped>
@media (max-width: 768px) {
  .slider-h-60 {
    height: 40vh!important;
  }
}
</style>