<template>
  <section class="section-2 showcase blog-grid filter-section projects">
    <div class="container">
      <div class="row items">
        <div class="col-12 col-md-4 pr-md-5 text">
          <div data-aos="fade-up" class="row">
            <div class="col-12 p-0">
              <span class="pre-title m-0">{{ $t("featured_articles") }}</span>
              <h2 class="mb-3">{{ $t("related_articles") }}</h2>
            </div>
          </div>
        </div>
        <div data-aos="fade-up" class="col-12 col-md-4 item">
          <div class="card p-0 text-center">
            <div class="image-over">
              <img :src="generateUrl(randomArticleA.pic)" alt="Lorem ipsum" />
            </div>
            <div
              class="
                card-footer
                d-lg-flex
                align-items-center
                justify-content-center
              "
            >
              <a href="#" class="d-lg-flex align-items-center"
                ><i class="fa-solid fa-user mr-5"></i>VC Lab</a
              >
              <a href="#" class="d-lg-flex align-items-center"
                ><i class="fa-solid fa-clock mr-5"></i>01.10.2022</a
              >
            </div>
            <div class="card-caption col-12 p-0">
              <div class="card-body">
                <router-link :to="`/articles/${randomArticleA.article_id}`">
                  <h4 style="text-transform:capitalize">{{humanNameA}}</h4>
                  <p>
                    {{randomArticleA.name}}
                  </p>
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div data-aos="fade-up" class="col-12 col-md-4 item">
          <div class="card p-0 text-center">
            <div class="image-over">
              <img :src="generateUrl(randomArticleB.pic)" alt="Lorem ipsum" />
            </div>
            <div
              class="
                card-footer
                d-lg-flex
                align-items-center
                justify-content-center
              "
            >
              <a href="#" class="d-lg-flex align-items-center"
                ><i class="fa-solid fa-user mr-5"></i>VC Lab</a
              >
              <a href="#" class="d-lg-flex align-items-center"
                ><i class="fa-solid fa-clock mr-5"></i>04.10.2022</a
              >
            </div>
            <div class="card-caption col-12 p-0">
              <div class="card-body">
                <router-link :to="`/articles/${randomArticleB.article_id}`">
                  <h4 style="text-transform:capitalize">{{humanNameB}}</h4>
                  <p>
                    {{randomArticleB.name}}
                  </p>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { articles_list } from '@/utils/raw_data'

export default {
  name: "RelatedArticles",
  setup() {
    const store = useStore()
    // now we only have articles in En and De, for all other langs, we display En
    const lang = computed(() => {
      const t = store.getters.browserlanguage
      return t === 'en' || t === 'de' ? t : 'en'
    })
    const articlesListOnUserLang = articles_list.find(obj => obj.lang === lang.value)

    const randNumberA = Math.floor(Math.random()*articlesListOnUserLang.details.length)
    const randNumberB = randNumberA-1 >= 0 ? randNumberA-1 : randNumberA+1 // make sure second random is within current array of articles
    const randomArticleA = articlesListOnUserLang.details[randNumberA]
    const randomArticleB = articlesListOnUserLang.details[randNumberB]

    const humanNameA = randomArticleA.article_id.replaceAll("_", " ")
    const humanNameB = randomArticleB.article_id.replaceAll("_", " ")

    onMounted(()=>{
      //..
    })

    function generateUrl(pic){
      return require(`@/assets/images/${pic}`)
    }

    return {
      articlesListOnUserLang,
      randomArticleA,
      randomArticleB,
      humanNameA,
      humanNameB,

      generateUrl,
    }
  }
}
</script>