<template>
  <Teleport to="body">
    <transition name="fade">
      <div v-if="showMenu">
        <div class="modal" style="display: block" @click="toggleAnimOff" />
        <div class="the-mob-menu">
          <i class="b-close-x" @click="toggleAnimOff"></i>

          <div
            class="the-mob-menu__content"
            style="min-width: 340px; padding: 20px 20px 5px 20px"
          >
            <ul class="navbar-nav items">
              <li class="nav-item border-bottom" @click="toggleAnimOff">
                <router-link to="/" class="smooth-anchor nav-link fs-18">{{
                  $t("home")
                }}</router-link>
              </li>
              <li class="nav-item border-bottom" @click="toggleAnimOff">
                <router-link to="/team" class="smooth-anchor nav-link fs-18">{{
                  $t("team")
                }}</router-link>
              </li>
              <li class="nav-item border-bottom">
                <router-link
                  @click="toggleAnimOff"
                  to="/services"
                  class="smooth-anchor nav-link fs-18"
                  >{{ $t("services") }}</router-link
                >
                <ul class="list-unstyled">
                  <li
                    class="nav-item"
                    v-for="(service, i) in serviceList"
                    :key="i"
                  >
                    <router-link
                      :to="service.link"
                      class="nav-link"
                      @click="toggleAnimOff"
                    >
                      <i
                        :class="`${service.icon}`"
                        class="mr-10"
                        style="color:rgba(0,0,0,0.3"
                      ></i>
                      {{ $t(service.name) }}
                    </router-link>
                  </li>
                </ul>
              </li>
              <li class="nav-item border-bottom" @click="toggleAnimOff">
                <router-link
                  to="/articles"
                  class="smooth-anchor nav-link fs-18"
                  >{{ $t("articles") }}</router-link
                >
              </li>
              <li class="nav-item" @click="toggleAnimOff">
                <router-link
                  to="/contacts"
                  class="smooth-anchor nav-link fs-18"
                  >{{ $t("contacts") }}</router-link
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </transition>
  </Teleport>

  <div class="bFlex__sb bFlex__c the-mob-header">
    <div class="p-10">
      <svg-logo isSmall />
    </div>

    <div>
      <button
        type="button"
        @click="toggleLangMenu"
        style="border: 0 !important; background: unset"
      >
        <i class="fas fa-globe fs-25 p-10"></i>
      </button>

      <button
        type="button"
        @click="showMenu = true"
        style="border: 0 !important; background: unset"
      >
        <i class="fas fa-bars fs-25 p-10 mr-5" style="z-index: 10"></i>
      </button>
    </div>
  </div>

  <!--------------------------------------------------------- SHOW LANGS -->
  <div v-if="showLangs">
    <div class="modal" style="display: block" @click="toggleLangMenu" />
    <ul class="list-unstyled p-20 lb-langmenu-list">
      <li v-for="(item, i) in lang_list" :key="`lang_${i}`" class="p-10">
        <button
          type="button"
          style="width: 100%; max-width: unset"
          class="btn btn-block smooth-anchor"
          :class="userLang === item.short ? 'primary-button' : 'outline-button'"
          @click="setSelectedLang(item.short)"
        >
          {{ $t(item.fullname) }}
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
import { ref, onMounted, computed, watch } from 'vue'
import { gsap } from 'gsap'
import i18n from '@/i18n'
import { useStore } from 'vuex'
import SvgLogo from './SvgLogo.vue'
import { LbServiceList } from '@/config/defaults'

export default {
  components: { SvgLogo },
  setup() {
    const store = useStore()
    const showMenu = ref(false)
    const showLangs = ref(false)
    const serviceList = LbServiceList
    const lang_list = [
      { fullname: 'en_full', short: "en" },
      { fullname: 'de_full', short: "de" },
      { fullname: 'zh_full', short: "zh" },
      { fullname: 'ru_full', short: "ru" },
    ]
    const userLang = computed(() => store.state.browser_language)

    onMounted(() => {
      setTimeout(() => {
        const c = ".the-mob-header"
        gsap.to(c, { y: 70, opacity: 1, duration: 0.26 })
      }, 250)
    })

    function toggleAnimOff() {
      gsap.to(".the-mob-menu", { alpha: 0, x: 0, y: 0, duration: 0.2, ease: "ease-in" })
      setTimeout(() => {
        showMenu.value = false
      }, 200)
    }

    function toggleAnimOn() {
      setTimeout(() => {
        const links = gsap.utils.toArray('.the-mob-menu a')
        gsap.set(links, { alpha: 0, x: -15 })
        gsap.timeline()
          .to(".the-mob-menu", { alpha: 1, x: -10, y: 10, duration: 0.3, ease: "ease-out" })
          .to(links, { alpha: 1, x: 0, duration: 0.2, stagger: 0.02, ease: "ease-in" }, "-=0.3")
      }, 50)
    }

    function toggleLangMenu() {
      // if OFF - animate, then turn ON, else - vice-versa
      if (!showLangs.value) {
        showLangs.value = true
        setTimeout(() => {
          gsap.to(".lb-langmenu-list", { alpha: 1, x: 0, y: 200, duration: 0.2, ease: "ease-in" })
        }, 50)
      } else {
        setTimeout(() => {
          gsap.to(".lb-langmenu-list", { alpha: 0, x: 0, y: -200, duration: 0.2, ease: "ease-in" })
          setTimeout(() => {
            showLangs.value = false
          }, 300)
        }, 50)
      }

    }

    function setSelectedLang(l) {
      store.dispatch("setLsLang", l)
      i18n.locale = l
      toggleLangMenu()
    }

    watch(
      () => showMenu.value,
      () => {
        if (showMenu.value) toggleAnimOn()
        else toggleAnimOff()
      }
    )

    return {
      showMenu,
      showLangs,
      serviceList,
      lang_list,
      userLang,

      toggleAnimOff,
      toggleLangMenu,
      setSelectedLang,
    }
  }
}
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.the-mob-header {
  position: absolute;
  top: -70px;
  left: 0;
  right: 0;
  z-index: 5;
  background-color: var(--section-2-bg-color);
}
.the-mob-menu {
  position: absolute;
  top: 0px;
  right: 0px;
  padding-top: 10px;
  overflow: hidden;
  z-index: 1051;
  background-color: #efefef;
  border-radius: 6px;
  max-width: 95%;

  & a {
    opacity: 0;
  }
}

.the-mob-menu__content:not(:last-child) {
  border-bottom: 7px solid var(--semi-transp-2);
}
.the-mob-menu__content a:not(:last-child) {
  border-bottom: 1px solid var(--semi-transp-1);
}

.lb-langmenu-list {
  position: absolute;
  left: 0;
  right: 0;
  top: -200px;
  opacity: 0;
  background-color: #efefef;
  z-index: 1051;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.lb-langmenu-list ul li:not(:last-child) {
  border-bottom: 1px solid var(--semi-transp-1);
}
</style>
