import { getFromLS, putToLS } from '@/utils/storage'
import Vuex from 'vuex'

const store = new Vuex.Store({
  state: {
    browser_language: '',
  },

  mutations: {
    setBrowserLanguage: (state, lang) => {
      state.browser_language = lang
    },
  },

  actions: {
    userLang: async ({ commit }) => {
      try {
        let lsLang = await getFromLS('nativeLang')
        if (!lsLang) {
          // full table of languages: https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes
          switch (navigator.language.split('-')[0]) {
            case 'zh': lsLang = 'zh'; break;
            case 'ru':
            case 'kk': // kazakh
            case 'be': // belarus
            case 'hy': // armenian
              lsLang = 'ru'; break;
            case 'de': lsLang = 'de'; break;
            case 'uz': lsLang = 'uz'; break;
            default: lsLang = 'en'
          }
          await putToLS('nativeLang', lsLang)
        }

        commit('setBrowserLanguage', lsLang)
        document.documentElement.setAttribute('lang', lsLang) // set html tag -> lang
      } catch (e) {
        console.warn('userLang: try-catch error', e)
      }
    },

    setLsLang: async ({ commit }, selectedLang) => {
      commit('setBrowserLanguage', selectedLang)
      await putToLS('nativeLang', selectedLang)
    },

    saveCookieAccept: async (_, bool) => {
      await putToLS('acceptCookies', bool)
    },
  },

  getters: {
    browserlanguage: state => state.browser_language,
  }
})

export default store