<template>
  <header id="header">
    <div class="lb-top-most-line">
      <div class="bFlex__sb container header" style="padding-top: 6px">
        <div class="bFlex__c">
          <lang-selector />
        </div>

        <ul class="bFlex list-unstyled">
          <li class="nav-item bFlex__c mr-20">
            <i class="fas fa-phone-alt mr-2"></i>+49 30 2359 19007
          </li>
          <li class="nav-item">
            <a href="mailto:info@ventureslab.de" class="nav-link"
              ><i class="fas fa-envelope mr-2"></i
            ></a>
          </li>
          <li class="nav-item social">
            <a href="#" class="nav-link"><i class="fab fa-linkedin-in"></i></a>
          </li>
        </ul>
      </div>
    </div>

    <!---------------------------------------------------------- NAVIGATION -->
    <nav
      class="navbar navbar-expand navbar-fixed sub"
      :class="{ 'ammended-color': menuColorDark }"
    >
      <div class="container header">
        <svg-logo />

        <div class="ml-auto"></div>

        <ul class="navbar-nav items">
          <li class="nav-item">
            <router-link to="/" class="smooth-anchor nav-link">{{
              $t("home")
            }}</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/team" class="smooth-anchor nav-link">{{
              $t("team")
            }}</router-link>
          </li>
          <li class="nav-item dropdown">
            <router-link to="/services" class="smooth-anchor nav-link">{{ $t("services") }}</router-link>
            <ul class="dropdown-menu">
              <li class="nav-item" v-for="(service, i) in serviceList" :key="i">
                <router-link :to="service.link" class="nav-link">
                  {{ $t(service.name) }}
                  <i
                    :class="`${service.icon}`"
                    class="ml-10"
                    style="color:rgba(0,0,0,0.3"
                  ></i>
                </router-link>
              </li>
            </ul>
          </li>
          <li class="nav-item">
            <router-link to="/articles" class="smooth-anchor nav-link">{{
              $t("articles")
            }}</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/contacts" class="smooth-anchor nav-link">{{
              $t("contacts")
            }}</router-link>
          </li>
        </ul>

        <ul class="navbar-nav toggle">
          <li class="nav-item">
            <a
              href="#"
              class="nav-link"
              data-toggle="modal"
              data-target="#menu"
            >
              <i class="icon-menu m-0"></i>
            </a>
          </li>
        </ul>
      </div>
    </nav>
  </header>
</template>

<script>
import SvgLogo from '@/components/SvgLogo.vue'
import { LbServiceList } from '@/config/defaults'
import LangSelector from './LangSelector.vue'

export default {
  name: "NavBar",
  components: { SvgLogo, LangSelector },
  data() {
    return {
      serviceList: LbServiceList,
    }
  },
  computed: {
    menuColorDark() {
      return this.$route.name === 'home'
    }
  }
};
</script>

<style scoped>
.navbar-fixed.ammended-color .nav-link {
  color: #21333e !important;
}

.dropdown-menu {
  max-width: 220px;
}

.lb-top-most-line {
  background-color: var(--nav-top-bg-color);
  height: 50px;
  position: relative;
}
.lb-top-most-line li {
  margin: 0;
}

.lb-top-most-line .nav-item,
.lb-top-most-line .nav-item .nav-link {
  transition: all 0.4s ease-out 0s;
  color: var(--secondary-p-color);
}
.lb-top-most-line .nav-item:hover,
.lb-top-most-line .nav-item a:hover {
  color: var(--secondary-color);
}
</style>