export const LbServiceList = [
  {
    name: "interim_cfo",
    desc: "interim_cfo_desc",
    icon: "fa-solid fa-briefcase",
    link: "/services/interim-cfo"
  }, {
    name: "fin_advisory",
    desc: "fin_advisory_desc",
    icon: "fa-solid fa-file-contract",
    link: "/services/fin-advisory"
  }, {
    name: "startup_consulting",
    desc: "startup_consulting_desc",
    icon: "fa-solid fa-chart-pie",
    link: "/services/startup-consulting"
  }, {
    name: "m_and_a",
    desc: "m_and_a_desc",
    icon: "fa-solid fa-magnifying-glass-chart",
    link: "/services/m-and-a"
  }, {
    name: "ifrs",
    desc: "ifrs_desc",
    icon: "fa-solid fa-gavel",
    link: "/services/ifrs"
  }, {
    name: "tax_and_legal",
    desc: "tax_and_legal_desc",
    icon: "fa-solid fa-scale-balanced",
    link: "/services/tax-and-legal"
  }
];