// taken from Bag project

export const putToLS = async (key, val) => {
  try {
    //let str = (typeof val == 'string') ? val : JSON.stringify(val)
    localStorage.setItem(
      key, 
      JSON.stringify(val)
    );
  } catch (e){
    console.log(key, e)
  }
}

export const getFromLS = async (key) => {
  try {
		const value = localStorage.getItem(key)
		return value && JSON.parse(value)
	} catch (e){
    console.log(key, e)
	}
}

export const rmvFromLS = async (key) => {
  localStorage.removeItem(key);
  // console.log('Storage item: ' + key + ' removed!');
}