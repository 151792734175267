<template>
  <section class="hero p-0 odd featured">
    <page-hero-tpl :specificName="humanName" />
  </section>

  <section class="section-1 single vclab-article-container">
    <div class="container">
      <div class="row content">
        <!---------------------------------------------------------- MAIN -->
        <div class="col-12 text">
          <div
            class="row"
            v-html="fileContent"
          ></div>
        </div>

        <!---------------------------------------------------------- SIDEBAR -->
      </div>
    </div>
  </section>

  <related-articles />
</template>

<script>
import { onMounted, computed } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import RelatedArticles from '@/components/RelatedArticles.vue'
import PageHeroTpl from '@/components/PageHeroTpl.vue'
import { marked } from 'marked'

import rental_virtual_land_en from '@/md/articles/en/rental_virtual_land.md'
import vat_on_gas_en from '@/md/articles/en/vat_on_gas.md'
import second_relief_package_en from '@/md/articles/en/second_relief_package.md'
import tax_relief_en from '@/md/articles/en/tax_relief.md'

import rental_virtual_land_de from '@/md/articles/de/rental_virtual_land.md'
import vat_on_gas_de from '@/md/articles/de/vat_on_gas.md'
import second_relief_package_de from '@/md/articles/de/second_relief_package.md'
import tax_relief_de from '@/md/articles/de/tax_relief.md'

export default {
  name: "ArticleTpl",
  components: { RelatedArticles, PageHeroTpl, },
  setup() {
    const store = useStore()
    const route = useRoute()
    const obj = {
      rental_virtual_land_en: rental_virtual_land_en,
      vat_on_gas_en: vat_on_gas_en,
      second_relief_package_en: second_relief_package_en,
      tax_relief_en: tax_relief_en,

      rental_virtual_land_de: rental_virtual_land_de,
      vat_on_gas_de: vat_on_gas_de,
      second_relief_package_de: second_relief_package_de,
      tax_relief_de: tax_relief_de,
    }
    const currentRouteName = route.params.article
    const humanName = currentRouteName.replaceAll("_", " ")
    const browserlanguage = computed(() => store.getters.browserlanguage)
    const fileContent = computed(() => {
      const tempCn = browserlanguage.value === 'de' ? 'de' : 'en' // we have only DE and EN versions atm
      return marked(`${obj[currentRouteName + '_' + tempCn]}`)
    })

    onMounted(() => {
      //..
    })

    return {
      humanName,
      fileContent,
    }
  },
}
</script>

<style>
  .vclab-article-container p {
    margin: 1.5rem 0 0 0;
  }
</style>