export default {
  "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En"])},
  "de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De"])},
  "ru": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ру"])},
  "zh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中文"])},
  "co_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Venture Consulting LAB"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Берлин, Германия"])},
  "VENTURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VENTURE"])},
  "CONSULTING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONSULTING LAB"])},
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Главная"])},
  "slogan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Компания с международным опытом в Берлине"])},
  "slogan_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Консультанты с международным опытом, рядом с Вами в Берлине"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Телефон"])},
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сообщение"])},
  "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить"])},
  "get_in_touch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["свяжитесь с нами"])},
  "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Узнать больше"])},
  "about_company_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["О"])},
  "about_company_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Компании"])},
  "about_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["О нас"])},
  "company_intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В Venture Consulting LAB мы разработаем вашу финансовую функцию для достижения ваших стратегических целей и оптимизации ее операционных потребностей"])},
  "security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Безопасность"])},
  "security_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Передовые отраслевые средства защиты обеспечат безопасность ваших данных"])},
  "trust": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доверие"])},
  "trust_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Как ваши доверенные советники, мы будем информировать вас о кибер- и стратегических рисках"])},
  "innovation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Инновации"])},
  "innovation_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мы используем технологии и превращаем данные в идеи для разработки стратегии"])},
  "expertise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Экспертиза"])},
  "expertise_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наша техническая хватка и консультационная аналитика повысят ценность вашего успеха"])},
  "key_services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Основные услуги"])},
  "key_services_header_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваши эксперты"])},
  "key_services_header_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["в ключевых услугах"])},
  "key_services_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мы являемся лидерами в предоставлении консультационных услуг в ключевых областях финансов, налогов, права, и связанных отраслях, с опытом как в Германии, так и по всему миру"])},
  "see_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все услуги"])},
  "how_it_works": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Процесс нашей работа с вами"])},
  "how_it_works_header_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Процесс"])},
  "how_it_works_header_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управления"])},
  "how_it_works_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мы используем современные методологии для обеспечения высокого качества услуг, соблюдения таймлайнов и успешного завершения ваших проектов"])},
  "Collection of information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сбор информации"])},
  "steps_desc_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "Strategic planning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стратегическое планирование"])},
  "steps_desc_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "Assignment of responsibilities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контракт и закрепление обязательств"])},
  "steps_desc_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "Formatting process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Формирование проектной команды"])},
  "steps_desc_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "Continuity formalization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Построение графика проекта"])},
  "steps_desc_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "our_services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наши сервисы"])},
  "select_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Интересующий вас сервис"])},
  "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Другое"])},
  "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сервисы"])},
  "team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Команда"])},
  "our_team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наша команда"])},
  "articles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статьи"])},
  "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контакты"])},
  "managing_partner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управляющий партнер"])},
  "partner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Партнер"])},
  "partner_finance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Партнер, Финансовые сервисы"])},
  "partner_startups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Партнер, IT Стартапы"])},
  "rights_reserved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ГМБХ &middot; Все права защищены"])},
  "email_protected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "cookies_and_privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Политики приватности и обработки данных"])},
  "cite_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я нанимаю людей умнее себя и ухожу с дороги"])},
  "cite_1_author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стив Джобс"])},
  "cite_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лучшее вложение — в собственное развитие"])},
  "cite_2_author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бенжамин Франклин"])},
  "cite_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Думаете ли вы, что можете или думаете, что не можете, вы правы!"])},
  "cite_3_author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Генри Форд"])},
  "cite_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Требуется 20 лет, чтобы заработать достойную репутацию, и достаточно 5 минут, чтобы ее разрушить. Если Вы будете об этом помнить, вы будете действовть соответственно."])},
  "cite_4_author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уоррен Баффит"])},
  "cite_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добивайтесь всегда большего, чем от Вас ожидают"])},
  "cite_5_author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ларри Пейдж"])},
  "cite_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Незнание налогового законодательства не освобождает вас от обязанности платить налоги. Знание - часто."])},
  "cite_6_author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Майер А. Ротшильд"])},
  "related_quote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цитата"])},
  "team_slogan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WE LIKE WHAT WE DO"])},
  "pro_team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span class='featured'><span>Команда</span></span> экспертов"])},
  "team_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ethics and integrity are the bases on which our professionals build their careers. They are fundamentals that become daily attitudes."])},
  "why_we": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Почему мы"])},
  "philosophy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мы знаем, как работают деньги. Если это работает у нас, будет работает и у вас"])},
  "timely_updates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Актуальные апдейты у вас на почте"])},
  "know_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span class='featured'><span>Узнайте</span></span> первыми"])},
  "know_first_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Узнайте о новостях из актуальных для вас областей первыми (рассылка делается с периодичностью не чаще 1 раза в 2 недели или реже)"])},
  "subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подписаться"])},
  "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tags"])},
  "interim_cfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш временный финансовый директор"])},
  "interim_cfo_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Беспристрастный взгляд, набор финансовых инструментов «голубых фишек» и опыт лидерства"])},
  "fin_advisory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Финансовые консультации"])},
  "fin_advisory_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проектная финансовая экспертиза и финансовая функция в корпоративной среде"])},
  "startup_consulting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Консультирование стартапов"])},
  "startup_consulting_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Практические советы: достижение вашим проетом поставленных целей"])},
  "m_and_a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["M&A / Реструктуризация компании"])},
  "m_and_a_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Слияния и поглощения (M&A) как стратегическая основа для развития бизнеса и возможностей роста"])},
  "ifrs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Внедрение / интерпретация МСФО"])},
  "ifrs_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обеспечить прозрачность и повысить сопоставимость финансовой информации для ваших инвесторов"])},
  "tax_and_legal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Международные налоги и право"])},
  "tax_and_legal_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Структурирование сложных международных проектов"])},
  "materials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Материалы"])},
  "featured_articles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Актуальные статьи"])},
  "related_articles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Избранные материалы по теме"])},
  "l_bush": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Любовь Буш"])},
  "a_rogov": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Алексей Рогов"])},
  "p_medvedev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Павел Медведев"])},
  "share_article": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поделиться в соцсетях"])},
  "other_services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Другие сервисы"])},
  "l_bush_exp_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Любовь является профессиональным руководителем в сфере налогообложения и финансов с 25-летним стажем коммерческой деятельности в т.ч. в крупных публичных компаниях. Проработав 15 лет в качестве генерального директора ООО «Венчурный Консалтинг», Любовь обладает богатым опытом в различных сферах бизнеса, комплексными знаниями в различных областях налогообложения, включая международные налоговые вопросы и вопросы налогообложения в ЕС."])},
  "l_bush_exp_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["До создания нового предприятия Любовь работала исполнительным директором голландской компании Lukarco Finance B.V. и финансовым директором дочерней компании Fischer Sports GmbH (Австрия)"])},
  "l_bush_exp_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Магистр Делового Администрирования в области банковского дела и финансов (MBA), Даулинг-колледж, Нью-Йорк"])},
  "l_bush_exp_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дипломированный аудитор (CPA)"])},
  "a_rogov_exp_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Алексей имеет более чем 20-летний опыт работы финансовым директором и членом совета директоров, за свою карьеру управлял финансовыми активами в объемах более 15 миллиардов долларов, участвовал в многочисленных международных проектах финансирования, обеспечивая финансовое и стратегическое руководство."])},
  "a_rogov_exp_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["До прихода в Venture Consulting LAB Алексей занимал должность начальника Департамента финансовых услуг в ПАО «Лукойл», где курировал кредитные линии для Группы и финансирование совместных предприятий в более чем 30 странах, занимался реструктурированием зарубежных активов Группы, разрабатывал сделки по слияниям и поглощениям, управлял внутригрупповым капиталом и дивидендной политикой"])},
  "a_rogov_exp_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Магистр Делового Администрирования в области Международного банковского дела и финансов (MBA)"])},
  "a_rogov_exp_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выпускник МФТИ с красным дипломом"])},
  "p_medvedev_exp_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ведущий специалист по новым технологиям. Павел обладает разносторонним пониманием построения и функционирования Стартапов и современных ИТ решений."])},
  "p_medvedev_exp_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Павел стал соучредителем и генеральным директором SaaS-стартапа Bagit, который обслуживал аудиторию из 15 стран и сумел добиться ежемесячного 30-процентного роста последовательно в течение 11 месяцев только за счет начальных инвестиций, интеграции крупнейших в мире шлюзов электронных платежей (PayPal, AliPay, WeChat Pay) и внедрения финтех-решений, обеспечив рост на очень сложных и быстро меняющихся рынках, включая Китай, Европу и СНГ/Россию"])},
  "p_medvedev_exp_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В настоящее время Павел также возглавляет службу Тех Продукта в группе Alibaba. В его карьере опыт работы руководителем подразделения Развития бизнеса в EY (Пекин)"])},
  "p_medvedev_exp_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Павел изучал Китайский язык и экономику Китая в Яншанском университете и ИСАА"])},
  "cookies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Согласно лучшим практикам уведомлений об использовании cookie, мы уведомляем о статусе использования cookie всех наших посетителей. Наш сайт не использует cookie."])},
  "understand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Понятно"])},
  "subscription_to_emails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новостная подписка"])},
  "uu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["uu"])}
}