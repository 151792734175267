<template>
  <section class="hero p-0 odd">
    <div
      class="
        no-slider
        animation
        slider-h-100 slider-h-auto
        alt
      "
    >
      <div class="swiper-wrapper" style="transform: translate3d(0px, 0px, 0px)">
        <div
          class="container"
        >
          <img
            src="../assets/images/bg-1.jpg"
            alt="Full Image"
            class="full-image"
            data-mask="80"
          />

          <div class="row">
            <div class="col-12 mx-auto inner">
              <h1 class="title effect-static-text">{{ $t("get_in_touch") }}</h1>

              <form class="">
                <div class="row">
                  <div class="col-12 col-lg-3 m-0 p-5 input-group">
                    <input
                      type="text"
                      name="name"
                      class="form-control field-name"
                      :placeholder="$t('name')"
                      v-model="name"
                    />
                  </div>
                  <div class="col-12 col-lg-3 m-0 p-5 input-group">
                    <input
                      type="email"
                      name="email"
                      class="form-control field-email"
                      :placeholder="$t('email')"
                      v-model="email"
                    />
                  </div>
                  <div class="col-12 col-lg-3 m-0 p-5 input-group">
                    <input
                      type="text"
                      name="phone"
                      class="form-control field-phone"
                      :placeholder="$t('phone')"
                      v-model="phone"
                    />
                  </div>
                  <div class="col-12 col-lg-3 m-0 p-5 input-group">
                    <i class="icon-arrow-down mr-3"></i>
                    <select v-model="selected_service" class="form-control field-info">
                      <option  disabled selected :value="null">
                        {{ $t("select_service") }}
                      </option>
                      <option v-for="(service, i) in serviceList" :key="i">
                        &mdash; {{ $t(service.name) }}
                      </option>
                      <option>&mdash; {{ $t("other") }}</option>
                    </select>
                  </div>
                  <div class="col-12 m-0 p-5 input-group">
                    <textarea
                      name="message"
                      class="form-control field-message"
                      :placeholder="$t('message')"
                      v-model="message"
                    ></textarea>
                  </div>
                  <div class="col-12 col-12 m-0 pl-md-2">
                    <span class="form-alert" style="display: none"></span>
                  </div>

                  <div
                    v-if="isAnyFieldEmpty"
                    class="
                      alert alert-warning alert-dismissible
                      invalid-feedback
                      fade
                      show
                      b_cp
                      ml-5
                    "
                    style="width:350px;"
                    @click="isAnyFieldEmpty = false"
                    role="alert"
                  >
                    Все поля обязательны для заполнения
                  </div>

                  <div class="col-12 input-group m-0 p-5">
                    <button type="button" @click="sendEmail" class="btn primary-button">{{
                      $t("send")
                    }}</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <span
        class="swiper-notification"
        aria-live="assertive"
        aria-atomic="true"
      ></span>
    </div>
  </section>
</template>

<script>
import { ref, toRefs, reactive } from 'vue'
import { LbServiceList } from '@/config/defaults'

export default {
  name: "LbTeam",
  components: { },
  setup() {
    const serviceList = LbServiceList
    const isAnyFieldEmpty = ref(false)
    const sendObj = reactive({
      name: null,
      email: null,
      phone: null,
      selected_service: null,
      message: null,
    })

    /**
     * Send Email with frontend tech // TODO: send via backend
     */
    function sendEmail() {
      isAnyFieldEmpty.value = Object.values(sendObj).some(val => !val)
      if (isAnyFieldEmpty.value) {
        return // <---- breakpoint
      }

      let email_body = ''
      Object.keys(sendObj).forEach(key => {
        email_body += key + ': ' + sendObj[key] + "%0D%0A"
      })

      location.href = `mailto:${process.env.VUE_APP_EMAIL}?subject=${sendObj.selected_service}&body=${email_body}`
    }

    return {
      serviceList,
      ...toRefs(sendObj),
      isAnyFieldEmpty,
      sendEmail,
    }
  }
}
</script>

<style scoped>
input::placeholder,
textarea::placeholder {
  color:#fff;
}
.odd select.form-control {
  color:#d6d6d6;
}
</style>