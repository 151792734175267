<template>
  <!-- Hero -->
  <section id="slider" class="hero p-0">
    <div
      class="
        swiper-container
        no-slider
        animation
        slider-h-100 slider-h-auto
        alt
      "
    >
      <div class="swiper-wrapper">
        <div class="swiper-slide slide-center">
          <div class="parallax-y-bg lb-hero-bg"></div>

          <div class="slide-content row">
            <div
              class="
                col-12
                d-flex
                justify-content-start justify-content-md-end
                inner
              "
            >
              <div class="right alt pb-0 text-left">
                <h1 class="title effect-static-text" style="font-size: 3rem">
                  <span class="pre-title m-0">{{ $t("slogan") }}</span>
                  {{ $t("co_name") }}
                </h1>

                <form
                  action="php/form.php"
                  id="nexgen-simple-form"
                  class="nexgen-simple-form"
                >
                  <div class="row form-group-margin lb-hero-form b-rad-4 bBg-rev-3 p-10">
                    <div class="col-12 col-md-6 mb-5 p-2 input-group">
                      <input
                        type="text"
                        name="name"
                        class="form-control field-name"
                        :placeholder="$t('name')"
                        v-model="name"
                      />
                    </div>
                    <div class="col-12 col-md-6 mb-5 p-2 input-group">
                      <input
                        type="email"
                        name="email"
                        class="form-control field-email"
                        :placeholder="$t('email')"
                        v-model="email"
                      />
                    </div>
                    <div class="col-12 col-md-6 mb-5 p-2 input-group">
                      <input
                        type="text"
                        name="phone"
                        class="form-control field-phone"
                        :placeholder="$t('phone')"
                        v-model="phone"
                      />
                    </div>
                    <div class="col-12 col-md-6 mb-5 p-2 input-group">
                      <i class="icon-arrow-down mr-3"></i>
                      <select
                        v-model="selected_service"
                        class="form-control field-info"
                      >
                        <option disabled selected :value="null">
                          {{ $t("select_service") }}
                        </option>
                        <option v-for="(service, i) in serviceList" :key="i">
                          &mdash; {{ $t(service.name) }}
                        </option>
                        <option>&mdash; {{ $t("other") }}</option>
                      </select>
                    </div>
                    <div class="col-12 col-12 pl-md-2">
                      <span class="form-alert"></span>
                    </div>

                    <div
                      v-if="isAnyFieldEmpty"
                      class="
                        alert alert-warning alert-dismissible
                        invalid-feedback
                        fade
                        show
                        b_cp
                        ml-5
                      "
                      style="width: 350px"
                      @click="isAnyFieldEmpty = false"
                      role="alert"
                    >
                      Все поля обязательны для заполнения
                    </div>

                    <div class="col-12 input-group m-0 p-2 buttons">
                      <div class="d-sm-inline-flex">
                        <button
                          type="button"
                          @click="sendEmail"
                          class="mt-4 mr-10 btn primary-button mb-mob-15"
                        >
                          {{ $t("get_in_touch") }}
                        </button>
                        
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section id="services" class="section-2 offers">
    <div class="container">
      <div class="row intro">
        <div class="col-12 col-md-9 align-self-center text-center text-md-left">
          <span class="pre-title" style="display:unset">{{ $t("key_services") }}</span>
          <h2>
            {{ $t("key_services_header_1") }}
            <span class="featured"
              ><span>{{ $t("key_services_header_2") }}</span></span
            >
          </h2>
          <p>
            {{ $t("key_services_desc") }}
          </p>
        </div>
      </div>
      <div class="row justify-content-center items">
        <template v-for="(service, i) in serviceList" :key="i">
          <router-link :to="service.link" class="col-12 col-md-6 col-lg-4 item">
            <div class="card">
              <p><i :class="`${service.icon} fa-3x`"></i></p>
              <h4 class="responsive-h4">{{ $t(service.name) }}</h4>
              <p>{{ $t(service.desc) }}</p>
            </div>
          </router-link>
        </template>
      </div>
    </div>
  </section>

  <!-- <process-management/> -->

  <section id="team" class="section-4 odd highlights team image-right">
    <div class="container">
      <div class="row">
        <div
          class="col-12 col-lg-8 align-self-top text-center text-md-left text"
        >
          <div class="row intro">
            <div class="col-12 p-0">
              <span class="pre-title m-auto m-md-0">{{
                $t("team_slogan")
              }}</span>
              <h2 v-html="$t('pro_team')" />
              <p>
                {{ $t("team_desc") }}
              </p>
            </div>
          </div>

          <div class="row">
            <div
              class="col-md-4 col-sm-12 mb-mob-20"
              v-for="(member, i) in theTeam"
              :key="`lba_team_member_${i}`"
              style="flex-grow: 1"
            >
              <router-link to="/team">
                <img :src="thePath(member.ava)" alt="Person" class="person" />
              </router-link>

              <router-link to="/team"
                ><h5 class="mb-5 lba-undelined-header">
                  {{ $t(member.name) }}
                </h5></router-link
              >
              <p class="mt-5 mb-0">{{ $t(member.position) }}</p>
              <ul class="navbar-nav social share-list ml-auto">
                <li class="nav-item mr-20">
                  <a :href="member.linkedin" class="nav-link" target="_blank"
                    ><i class="fab fa-linkedin"></i
                  ></a>
                </li>
                <li class="nav-item">
                  <a
                    :href="`mailto:${member.email}`"
                    class="nav-link"
                    target="_blank"
                    ><i class="fas fa-envelope"></i
                  ></a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div data-aos="zoom-in" class="col-12 col-lg-4 align-self-end mt-mob-20">
          <div class="quote">
            <div class="quote-content">
              <h4>{{ $t("why_we") }}</h4>
              <p>
                {{ $t("philosophy") }}
              </p>
              <h5>{{ $t("co_name") }}</h5>

              <!-- <p class="mt-10">
                Check out more on our team
                <router-link
                  style="
                    color: var(--secondary-p-color);
                    text-decoration: underline;
                    font-weight: 700;
                  "
                  to="/team"
                  >here</router-link
                >
              </p> -->
            </div>
            <i class="quote-right fas fa-quote-right"></i>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- Company -->
  <about-company/>

  <lb-subscribe />
</template>

<script>
import { ref, toRefs, reactive } from 'vue'
import { LbServiceList } from '@/config/defaults'
import { lba_team } from '@/utils/raw_data'
import LbSubscribe from '@/components/LbSubscribe.vue'
import AboutCompany from '@/views/Home/components/AboutCompany.vue'

export default {
  name: "LbHome",
  components: {
    LbSubscribe,
    AboutCompany,
  },
  setup() {
    const serviceList = LbServiceList
    const theTeam = lba_team
    const isAnyFieldEmpty = ref(false)
    const sendObj = reactive({
      name: null,
      email: null,
      phone: null,
      selected_service: null,
    })

    /**
     * Send Email with frontend tech // TODO: send via backend
     */
    function sendEmail() {
      console.log()
      isAnyFieldEmpty.value = Object.values(sendObj).some(val => !val)
      if (isAnyFieldEmpty.value) {
        return // <---- breakpoint
      }

      let email_body = ''
      Object.keys(sendObj).forEach(key => {
        email_body += key + ': ' + sendObj[key] + "%0D%0A"
      })

      location.href = `mailto:${process.env.VUE_APP_EMAIL}?subject=${sendObj.selected_service}&body=${email_body}`
    }

    return {
      serviceList,
      theTeam,
      isAnyFieldEmpty,
      ...toRefs(sendObj),
      sendEmail,
    }
  },
  methods: {
    thePath(ava) {
      return require(`@/assets/images/${ava}`);
    },
  },
};
</script>

<style>
:root {
  --header-bg-color: #f9f9f9;
  --nav-item-color: #21333e;
  --hero-bg-color: #f9f9f9;

  --section-2-bg-color: #eef4ed;
  --section-3-bg-color: #ffffff !important;
  --section-4-bg-color: #111117;
  --section-5-bg-color: #ffffff;
  --section-6-bg-color: #111117;
}

.lba-undelined-header {
  text-decoration: underline;
}
.lba-undelined-header:hover {
  color: rgb(159, 232, 215);
}
.lb-hero-form .form-control {
  background-color: rgba(0, 0, 0, 0.15);
}

.lb-hero-form .form-control::placeholder,
.lb-hero-form select.form-control option,
.lb-hero-form select.form-control {
  color: rgb(43, 43, 43);
  font-weight: 500;
}
</style>