<template>
  <aside class="col-12 col-lg-4 pl-lg-5 p-0 float-right sidebar">
    <!-- Share -->
    <div class="row item widget-share">
      <div class="col-12 align-self-center">
        <h4 class="title text-muted">{{ $t("share_article") }}</h4>
        <ul class="navbar-nav social share-list">
          <li class="nav-item mr-20">
            <a href="#" class="nav-link"><i class="fab fa-linkedin-in"></i></a>
          </li>
          <li class="nav-item mr-20">
            <a href="#" class="nav-link"
              ><i class="fas fa-envelope mr-2"></i
            ></a>
          </li>
          <li class="nav-item">
            <a href="#" class="nav-link"><i class="fas fa-print"></i></a>
          </li>
        </ul>
      </div>
    </div>

    <div class="row item widget-services">
      <div class="col-12 align-self-center">
        <h4 class="title text-muted">{{ $t("other_services") }}</h4>
        <div class="quote">
          <ul class="list-group list-group-flush">
            <li
              v-for="(item, i) in lbs"
              :key="`lbs_service_${i}`"
              class="
                list-group-item
                d-flex
                justify-content-start
                align-items-center
              "
            >
              <i
                class="lba-sidebar-related-services b-rad-8 mr-20"
                :class="item.icon"
              ></i>
              <div class="list-group-content">
                <router-link :to="item.link" style="text-decoration: underline"
                  ><h5>{{ $t(item.name) }}</h5></router-link
                >
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- Tags -->
    <!-- <div class="row item widget-tags">
      <div class="col-12 align-self-center">
        <h4 class="title">{{ $t("tags") }}</h4>
        <div class="badges">
          <span class="badge"><a href="#">Consulting</a></span>
          <span class="badge"><a href="#">Interim CFO</a></span>
          <span class="badge"><a href="#">Company restructure</a></span>
          <span class="badge"><a href="#">Startups</a></span>
        </div>
      </div>
    </div> -->

    <!-- Quote -->
    <div
      class="row item widget-quote team"
      v-if="lbQuotes[currentRoute].text != ''"
    >
      <div class="col-12 align-self-center">
        <div class="quote">
          <div class="quote-content">
            <h4>{{ $t("related_quote") }}</h4>
            <p>
              {{ $t(lbQuotes[currentRoute].text) }}
            </p>
            <h5>{{ $t(lbQuotes[currentRoute].author) }}</h5>
          </div>
          <i class="quote-right fas fa-quote-right"></i>
        </div>
      </div>
    </div>
  </aside>
</template>
<script>
import { ref, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { LbQuotesObj } from '@/config/lbQuotesObj'
import { LbServiceList } from '@/config/defaults'

export default {
  setup() {
    const route = useRoute()
    const lbQuotes = LbQuotesObj
    const currentRoute = route.name
    const lbs = LbServiceList.filter(el => el.name !== currentRoute)

    onMounted(() => {
      console.log(currentRoute)
    })

    return {
      lbs,
      lbQuotes,
      currentRoute,
    }
  }
};
</script>

<style>
.lba-sidebar-related-services {
  padding: 10px;
  background-color: var(--primary-bg-color-3);
  width: 40px;
  height: 40px;
}
</style>