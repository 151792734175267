export default {
  "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En"])},
  "de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De"])},
  "ru": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ру"])},
  "zh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中文"])},
  "co_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Venture Consulting LAB"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berlin, Germany"])},
  "VENTURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VENTURE"])},
  "CONSULTING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONSULTING"])},
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["首页"])},
  "slogan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["柏林公司，国际经验"])},
  "slogan_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Best international consulting, right next to you in Berlin"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["姓名"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["邮件"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电话"])},
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["消息"])},
  "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发送"])},
  "get_in_touch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["联系我们"])},
  "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["了解更多"])},
  "about_company_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["关于"])},
  "about_company_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们"])},
  "about_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["关于我们"])},
  "company_intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在 Venture Consulting LAB 我们将设计您的财务功能以满足您的战略目标并优化贵公司的运营线"])},
  "security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["安全"])},
  "security_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Industry vanguard safeguards will keep your data safe"])},
  "trust": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["相信"])},
  "trust_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["作为您值得信赖的顾问，我们将告知您网络和战略风险"])},
  "innovation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创新"])},
  "innovation_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We leverage technology & turn data into insight to strategize"])},
  "expertise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["专业知识"])},
  "expertise_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our tech acumen & advisory analytics will add value to your success"])},
  "key_services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["关键服务"])},
  "key_services_header_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["卓越的"])},
  "key_services_header_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["服务"])},
  "key_services_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are leaders in providing consultancy services supported by our cutting-edge experience on tax, law, finance and other adjacent spheres, both internationally and in Germany."])},
  "see_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["了解更多"])},
  "how_it_works": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How it works in practice"])},
  "how_it_works_header_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["过程"])},
  "how_it_works_header_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["管理"])},
  "how_it_works_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We work with innovative methodologies to ensure that the entire reformatting process is done from start to finish as planned."])},
  "Collection of information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["集合有关信息"])},
  "steps_desc_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "Strategic planning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["策略计划"])},
  "steps_desc_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "Assignment of responsibilities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["职责分配"])},
  "steps_desc_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "Formatting process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["企业格式化流程"])},
  "steps_desc_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "Continuity formalization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuity formalization"])},
  "steps_desc_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "our_services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们服务"])},
  "select_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择相关服务"])},
  "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["其他"])},
  "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["服务"])},
  "team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["团队"])},
  "our_team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们团队"])},
  "articles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文章"])},
  "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["联系方式"])},
  "managing_partner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["主管合伙人"])},
  "partner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合伙人"])},
  "partner_finance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合伙人，金融服务"])},
  "partner_startups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合伙人，Startups & Tech"])},
  "rights_reserved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GMBH &middot; 版权所有"])},
  "email_protected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看邮箱"])},
  "cookies_and_privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cookie 和隐私政策"])},
  "cite_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I hire people smarter than me and get out of the way"])},
  "cite_1_author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["史蒂夫·乔布斯"])},
  "cite_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The best investment is in the tools of one's own trade"])},
  "cite_2_author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["本杰明·富兰克林"])},
  "cite_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whether you think you can or whether you think you can't, you're right!"])},
  "cite_3_author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["亨利·福特"])},
  "cite_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "cite_4_author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "cite_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ignorance of tax laws does not exempt you from the obligation to pay taxes.  But the knowledge often"])},
  "cite_5_author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["迈尔·阿姆谢尔·罗斯柴尔德"])},
  "cite_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "cite_6_author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "related_quote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Related quote"])},
  "team_slogan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们喜欢我们所做的"])},
  "pro_team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span class='featured'><span>专业</span></span> 团队"])},
  "team_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ethics and integrity are the bases on which our professionals build their careers. They are fundamentals that become daily attitudes."])},
  "why_we": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["为什么选我们"])},
  "philosophy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We know how money works.  If it works for us, it will work for you."])},
  "timely_updates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收到及时更新"])},
  "know_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span class='featured'><span>Know</span></span> First"])},
  "know_first_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密切关注并接收有关我们公司的内容和当前市场的新闻"])},
  "subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订阅"])},
  "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["标签"])},
  "interim_cfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["临时首席财务官"])},
  "interim_cfo_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unbiased perspective, blue chip finance tool' kit and leadership expertise"])},
  "fin_advisory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["财务咨询"])},
  "fin_advisory_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project based financial expertise and finance function in a corporate setting"])},
  "startup_consulting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["初创公司咨询"])},
  "startup_consulting_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hands on advice to get your real money-making venture over to the goal line"])},
  "m_and_a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合并和收购公司，企业重组"])},
  "m_and_a_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["M&A as a strategic footing for new businesses and opportunities"])},
  "ifrs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["国际财务报告准则咨询"])},
  "ifrs_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To bring transparency & enhance comparability of financial information for your investors"])},
  "tax_and_legal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["国际税务与法律"])},
  "tax_and_legal_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complex international projects structuring"])},
  "materials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["资料"])},
  "featured_articles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["推荐文章"])},
  "related_articles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有关常见资料"])},
  "l_bush": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liubov Bush 卢巴"])},
  "a_rogov": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alexey Rogov 阿列克谢"])},
  "p_medvedev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pavel Medvedev 巴维"])},
  "share_article": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分享文章"])},
  "other_services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["其他有关服务"])},
  "l_bush_exp_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liubov is an accomplished Tax and Finance industry executive with 25 years of commercial and general management experience at both public and private companies. Having spent 15 years as the CEO at Venture Consulting LLC, Liubov brings a wealth of experience to numerous business areas and integrated knowledge of a variety of tax areas, including international and EU taxation."])},
  "l_bush_exp_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liubov served as executive director at Lukarco Finance B.V. (the Netherlands) and CFO of a regional subsidiary of Fischer Sports GmbH (Austria)"])},
  "l_bush_exp_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MBA in Banking and Finance from Dowling College of New York"])},
  "l_bush_exp_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CPA注册会计师"])},
  "a_rogov_exp_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alexey has over 20 years of experience as CFO and board director, managing financial assets in the amount of $15 billion. During his career, he has participated in preparing and conducting several public offerings and raising finance deals, providing strategic leadership and creating shareholder value. "])},
  "a_rogov_exp_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prior to joining Venture Consulting LAB Alexey was Head of the Financial Services Department at PJSC Lukoil where he oversaw the group's and JVs' financial facilities in more than 30 countries. Alexey restructured group foreign assets, designed M&A deals and managed intragroup capital/dividend policy."])},
  "a_rogov_exp_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["纽约道林学院 银行和金融MBA"])},
  "a_rogov_exp_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MS in Electronics by MIPT"])},
  "p_medvedev_exp_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pavel has a versatile understanding of tech startups and product/business processes."])},
  "p_medvedev_exp_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pavel cofounded and CEOed the SaaS startup Bagit which served customers from 15 countries and achieved a 30% MoM growth in 11 straight months with only seed investment. In his project he implemented many fintech solutions and integrated the world's biggest ePayment gateways (AliPay, WeChat Pay, PayPal). Bagit demonstrated stable growth in highly complicated, fast-pace markets, including China, Europe and CIS/Russia"])},
  "p_medvedev_exp_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currently Pavel is also Head of Tech Product at Alibaba group. Previously, he had also managed the BD desk at EY Beijing"])},
  "p_medvedev_exp_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pavel studied Chinese language and economy at the  Yanshan University and at the Institute of Asian and African Countries"])},
  "cookies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["According to cookie policy best practice, we inform all our users about cookie usage by our website. Our website does not use cookies."])},
  "understand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["了解"])},
  "subscription_to_emails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscription"])},
  "uu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["uu"])}
}