<template>
  <section id="slider" class="hero p-0 odd featured">
    <div
      class="
        swiper-container
        no-slider
        animation
        slider-h-50 slider-h-auto
        swiper-container-initialized swiper-container-horizontal
      "
    >
      <div class="swiper-wrapper" style="transform: translate3d(0px, 0px, 0px)">
        <!-- Item 1 -->
        <div class="swiper-slide slide-center swiper-slide-active">
          <!-- Media -->
          <img
            src="@/assets/images/bg-wide.jpg"
            alt="Full Image"
            class="full-image"
            data-mask="80"
          />

          <div class="slide-content row text-center">
            <div class="col-12 mx-auto inner">
              <nav
                aria-label="breadcrumb"
                class="aos-init aos-animate desktop"
              >
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <a href="/">{{ $t("Home") }}</a>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    {{ $t("our_team") }}
                  </li>
                </ol>
              </nav>

              <h1 class="mb-0 title effect-static-text">
                {{ $t("our_team") }}
              </h1>
            </div>
          </div>
        </div>
      </div>
      <span
        class="swiper-notification"
        aria-live="assertive"
        aria-atomic="true"
      ></span>
    </div>
  </section>

  <section id="team" class="section-1 team">
    <div class="container">
      <div class="row text-center intro">
        <div class="col-12">
          <span class="pre-title m-auto">{{ $t("team_slogan") }}</span>
          <h2 v-html="$t('pro_team')" />
        </div>
      </div>
      <div class="row justify-content-center items">
        <div class="col-12 col-md-4 col-lg-4 item">
          <img
            src="@/assets/images/liubov_bush.jpg"
            alt="Full Image"
            data-mask="10"
          />
        </div>
        <div class="col-12 col-md-7 col-lg-7 item">
          <h4>{{ $t("l_bush") }}</h4>
          <strong>{{ $t("managing_partner") }}</strong>
          <p style="text-align: justify">
            {{ $t("l_bush_exp_1") }}
          </p>
          <p>
            <i class="fa-solid fa-angle-right mr-10"></i
            >{{ $t("l_bush_exp_2") }}
          </p>
          <p>
            <i class="fa-solid fa-angle-right mr-10"></i
            >{{ $t("l_bush_exp_3") }}
          </p>
          <p>
            <i class="fa-solid fa-angle-right mr-10"></i
            >{{ $t("l_bush_exp_4") }}
          </p>

          <ul class="navbar-nav social share-list ml-auto">
            <li class="nav-item mr-20">
              <a href="https://linkedin.com/in/liubov-bush-mba-cpa-a90032172" class="nav-link" target="_blank"
                ><i class="fab fa-linkedin"></i
              ></a>
            </li>
            <li class="nav-item">
              <a
                href="mailto:info@ventureslab.de"
                class="nav-link"
                target="_blank"
                ><i class="fas fa-envelope"></i
              ></a>
            </li>
          </ul>
        </div>
      </div>

      <div class="row justify-content-center items">
        <div class="col-12 col-md-4 col-lg-4 item">
          <img
            src="@/assets/images/alexey_rogov.jpg"
            alt="Full Image"
            data-mask="10"
          />
        </div>
        <div class="col-12 col-md-7 col-lg-7 item">
          <h4>{{ $t("a_rogov") }}</h4>
          <strong>{{ $t("partner") }}</strong>
          <p style="text-align: justify">{{ $t("a_rogov_exp_1") }}</p>
          <p>
            <i class="fa-solid fa-angle-right mr-10"></i
            >{{ $t("a_rogov_exp_2") }}
          </p>
          <p>
            <i class="fa-solid fa-angle-right mr-10"></i
            >{{ $t("a_rogov_exp_3") }}
          </p>
          <p>
            <i class="fa-solid fa-angle-right mr-10"></i
            >{{ $t("a_rogov_exp_4") }}
          </p>

          <ul class="navbar-nav social share-list ml-auto">
            <li class="nav-item mr-20">
              <a href="https://linkedin.com/in/alexey-l-rogov" class="nav-link" target="_blank"
                ><i class="fab fa-linkedin"></i
              ></a>
            </li>
            <li class="nav-item">
              <a
                href="mailto:info@ventureslab.de"
                class="nav-link"
                target="_blank"
                ><i class="fas fa-envelope"></i
              ></a>
            </li>
          </ul>
        </div>
      </div>

      <div class="row justify-content-center items">
        <div class="col-12 col-md-4 col-lg-4 item">
          <img
            src="@/assets/images/pavel_me.jpg"
            alt="Full Image"
            style="width:100%"
            data-mask="10"
          />
        </div>
        <div class="col-12 col-md-7 col-lg-7 item">
          <h4>{{ $t("p_medvedev") }}</h4>
          <strong>{{ $t("partner_startups") }}</strong>
          <p style="text-align: justify">{{ $t("p_medvedev_exp_1") }}</p>
          <p>
            <i class="fa-solid fa-angle-right mr-10"></i
            >{{ $t("p_medvedev_exp_2") }}
          </p>
          <p>
            <i class="fa-solid fa-angle-right mr-10"></i
            >{{ $t("p_medvedev_exp_3") }}
          </p>  
          <p>
            <i class="fa-solid fa-angle-right mr-10"></i
            >{{ $t("p_medvedev_exp_4") }}
          </p>

          <ul class="navbar-nav social share-list ml-auto">
            <li class="nav-item mr-20">
              <a href="https://linkedin.com/in/pamedvedev" class="nav-link" target="_blank"
                ><i class="fab fa-linkedin"></i
              ></a>
            </li>
            <li class="nav-item">
              <a
                href="mailto:info@ventureslab.de"
                class="nav-link"
                target="_blank"
                ><i class="fas fa-envelope"></i
              ></a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { lba_team } from "@/utils/raw_data"

export default {
  name: "LbTeam",
  components: {
    //..
  },
  setup() {
    const theTeam = lba_team

    return {
      theTeam
    }
  }
};
</script>