export default {
  "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En"])},
  "de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De"])},
  "ru": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ру"])},
  "zh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中文"])},
  "co_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Venture Consulting LAB"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berlin, Deutschland"])},
  "VENTURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VENTURE"])},
  "CONSULTING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONSULTING LAB"])},
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "slogan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berliner Unternehmen, internationale Erfahrung"])},
  "slogan_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beste internationale Beratung Service, direkt neben Ihnen in Berlin"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon"])},
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meldung"])},
  "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senden"])},
  "get_in_touch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktieren Sie uns"])},
  "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehr erfahren"])},
  "about_company_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DAS"])},
  "about_company_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UNTERNEHMEN"])},
  "about_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Über uns"])},
  "company_intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bei der Venture Consulting LAB gestalten wir Ihre Finanzfunktion so, dass sie Ihre strategischen Ziele erfüllt und Ihre operativen Anforderungen optimiert"])},
  "security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SICHERHEIT"])},
  "security_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sicherheitsvorkehrungen der Branche schützen Ihre Daten"])},
  "trust": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VERTRAUEN"])},
  "trust_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als Ihre vertrauten Berater informieren wir Sie über Cyber- & Strategierisiken"])},
  "innovation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INNOVATION"])},
  "innovation_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir nutzen Technologie und verwandeln Daten in Erkenntnisse für Strategien"])},
  "expertise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SACHVERSTAND"])},
  "expertise_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unser technischer Scharfsinn und unsere Beratungsanalysen werden Ihren Erfolg steigern"])},
  "key_services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schwerpunkte"])},
  "key_services_header_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excellence in"])},
  "key_services_header_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services"])},
  "key_services_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir sind führend in der Erbringung von Beratungsdienstleistungen und greifen auf unsere wertvolle Erfahrung in Finanzen, Steuern, Recht und anderen verwandten Bereichen sowohl international als auch in Deutschland zurück."])},
  "see_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See all"])},
  "how_it_works": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How it works in practice"])},
  "how_it_works_header_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Process"])},
  "how_it_works_header_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Management"])},
  "how_it_works_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We work with innovative methodologies to ensure that the entire reformatting process is done from start to finish as planned."])},
  "Collection of information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collection of information"])},
  "steps_desc_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "Strategic planning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strategic planning"])},
  "steps_desc_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "Assignment of responsibilities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assignment of responsibilities"])},
  "steps_desc_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "Formatting process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formatting process"])},
  "steps_desc_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "Continuity formalization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuity formalization"])},
  "steps_desc_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "our_services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unsere Leistungen"])},
  "select_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie den Dienst aus"])},
  "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])},
  "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leistungen"])},
  "team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Team"])},
  "our_team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Team"])},
  "articles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikel"])},
  "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt"])},
  "managing_partner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschäftsführende Gesellschafterin"])},
  "partner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partner"])},
  "partner_finance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partner,\nFinancial Services"])},
  "partner_startups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partner, Leiter Technologielösungen"])},
  "rights_reserved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GMBH &middot; All rights reserved"])},
  "email_protected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "cookies_and_privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cookies & Privacy policies"])},
  "cite_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich stelle Leute ein, die smarter sind als ich und gehe aus dem Weg"])},
  "cite_1_author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steve Jobs"])},
  "cite_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die beste Investition ist die in das eigene Handwerkszeug"])},
  "cite_2_author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benjamin Franklin"])},
  "cite_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ob Sie denken, dass Sie es können oder ob Sie denken, dass Sie es nicht können, Sie haben Recht!"])},
  "cite_3_author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Henry Ford"])},
  "cite_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es dauert 20 Jahre, um einen guten Ruf aufzubauen, und 5 Minuten um ihn zu ruinieren. Wenn Sie darüber nachdencken werden Sie die Dinge anders machen."])},
  "cite_4_author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warren Baffet"])},
  "cite_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liefern Sie immer mehr als erwartet"])},
  "cite_5_author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Larry Page"])},
  "cite_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Unwissenheit der Steuergesetze befreit Sie nicht von der Verpflichtung, Steuern zu zahlen. Aber das Wissen oft"])},
  "cite_6_author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meyer A. Rothschild"])},
  "related_quote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zitat"])},
  "team_slogan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir mögen was wir tun"])},
  "pro_team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span class='featured'><span>Professionelles</span></span> Team"])},
  "team_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ethik und Integrität sind die Grundlagen, auf denen unsere Fachleute ihre Karriere aufbauen. Sie sind Grundlagen, die zu täglichen Einstellungen werden."])},
  "why_we": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Why we"])},
  "philosophy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We know how money works.  If it works for us, it will work for you."])},
  "timely_updates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erhalten Sie zeitnahe Updates"])},
  "know_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span class='featured'><span>Know</span></span> First"])},
  "know_first_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verfolgen Sie unsere Nachrichten"])},
  "subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonieren"])},
  "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tags"])},
  "interim_cfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Interim CFO"])},
  "interim_cfo_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unvoreingenommene Einschätzung, Blue-Chip-Finanz-Tools-Kit und Führungskompetenz"])},
  "fin_advisory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finanzberatungsdienste"])},
  "fin_advisory_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projektbasierte Finanzexpertise und Finanzfunktion in einem Unternehmensumfeld"])},
  "startup_consulting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["StartUp Beratung"])},
  "startup_consulting_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Praktische Ratschläge, um Ihr echtes Geldverdienen an die Ziellinie zu bringen"])},
  "m_and_a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["M&A, Umstrukturierungen"])},
  "m_and_a_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["M&A als strategische Basis für neue Geschäfte und Möglichkeiten"])},
  "ifrs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IFRS Implementierung"])},
  "ifrs_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transparenz und die Vergleichbarkeit von Finanzinformationen für Ihre Investoren"])},
  "tax_and_legal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internationale Steuern und Recht"])},
  "tax_and_legal_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strukturierung komplexer internationaler Projekte"])},
  "materials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Materials"])},
  "featured_articles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["information"])},
  "related_articles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausgewählte Artikel zum Thema"])},
  "l_bush": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liubov Bush"])},
  "a_rogov": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alexey Rogov"])},
  "p_medvedev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pavel Medvedev"])},
  "share_article": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teilen Sie diesen Artikel"])},
  "other_services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ähnliche Dienstleistungen"])},
  "l_bush_exp_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liubov ist eine versierte Führungskraft in den Steuer- und Finanzbereichen. Liubov verfügt über 25 Jahre Kommerzialisierungs- und allgemeine Managementerfahrung in zahlreichen leitenden Führungspositionen in öffentlichen und privaten Unternehmen. Nach 15 Jahren als CEO bei Venture Consulting LLC bringt Liubov einen reichen Erfahrungsschatz und integriertes Wissen in verschiedenen Steuerbereichen einschließlich internationaler und EU-Steuerangelegenheiten mit."])},
  "l_bush_exp_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vor der Gründung eines neuen Unternehmens war Liubov Executive Director bei dem niederländischen Unternehmen Lukarco Finance B.V. und CFO bei einer regionalen Niederlassung Fischer Sports AG (Österreich)"])},
  "l_bush_exp_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MBA in Bank- und Finanzwesen am Dowling College of New York"])},
  "l_bush_exp_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CPA-qualifizierte Wirtschaftsprüferin"])},
  "a_rogov_exp_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alexey verfügt über mehr als 20 Jahre Erfahrung als Chief Financial Officer und Board Director, verwaltete Finanzanlagen in Höhe von insgesamt 15 Milliarden US-Dollar, war an der Vorbereitung und Durchführung mehrerer öffentlicher Angebote und der Beschaffung von Finanzierungsgeschäften für Privatunternehmen beteiligt."])},
  "a_rogov_exp_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vor seinem Eintritt bei Venture Consulting LAB leitete Alexey die Finanzdienstleistungsabteilung von PJSC Lukoil. Alexey beaufsichtigte die Kreditlinien für die Gruppe und die Finanzierung ihrer Joint Ventures in mehr als 30 Ländern, restrukturierte die ausländischen Vermögenswerte der Gruppe, bereitete Fusionen und Übernahmen vor, verwaltete das gruppeninterne Kapital und die Dividendenpolitik"])},
  "a_rogov_exp_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MBA in International Banking and Finance"])},
  "a_rogov_exp_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MS in Electronics am MIPT"])},
  "p_medvedev_exp_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pavel verfügt über ein vielseitiges Verständnis für Tech-Startups sowie Produkt- und Geschäftsprozesse."])},
  "p_medvedev_exp_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pavel war Gründer und CEO eines SaaS-Startups Bagit, das Kunden aus 15 Ländern betreute und es schaffte, bereits auf „Seed Stage“ ein 30 % MoM-Wachstum in geraden 11 Monaten zu erreichen, indem er die weltweit größten ePayment-Gateways (AliPay, PayPal, WeChat Pay) integrierte und Fintech-Lösungen implementierte. Der StartUp zeigte eine nachhaltige Entwicklung in hochkomplizierten, schnelllebigen Märkten, darunter China, Europa und GUS/Russland"])},
  "p_medvedev_exp_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Derzeit ist Pavel auch Head of Tech Product bei der Alibaba-Gruppe. Außerdem leitete Pavel den BD-Desk bei EY Beijing"])},
  "p_medvedev_exp_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pavel studierte chinesische Sprache und Wirtschaft an der Yanshan-Universität in China und am Institut der Länder Asiens und Afrikas"])},
  "cookies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["According to cookie policy best practice, we inform all our users about cookie usage by our website. Our website does not use cookies."])},
  "understand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Got it"])},
  "subscription_to_emails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscription"])},
  "uu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["uu"])}
}